import React, { useEffect, useReducer, useState } from "react";
import CardItem from "./CardItemBD";
import CardItemBDBteste from "./CardItemBDBteste";
import Spinner from "react-bootstrap/Spinner";
import Counter from "../BDSerachMark/Counter";
import SearchBar from "../BDSerachMark/SearchBar";
import Documentos from "../DocumentosPdf/Documentos"; // Caminho correto para o componente Documentos
import "./CardsBd.css";

const initialState = {
  isLoading: true,
  data: [],
  search: "",
  searchData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case "SEARCH_INPUT":
      return { ...state, search: action.payload };
    case "SEARCH_DATA":
      return { ...state, searchData: action.payload };
    default:
      throw new Error();
  }
};

const CardsVerbetes = () => {
  // Estado para alternar entre "Verbetes" e "Documentos"
  const [searchType, setSearchType] = useState("verbetes");

  const [visibleCount, setVisibleCount] = useState(4); // Controle de exibição em lotes
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  const fetchData = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      dispatch({ type: "SET_DATA", payload: data });
    } catch (err) {
      console.log("Error:", err);
    }
  };

  useEffect(() => {
    fetchData("https://siteapi.umahistoriadapeninsula.com/api/umahistoria/");
  }, []);

  const handleInput = (e) => {
    const str = e.target.value;
    dispatch({ type: "SEARCH_INPUT", payload: str });
    const newArr = state.data
      .filter((item) =>
        ["title", "description", "palavrachave", "textoverbete", "autor"].some(
          (key) => item[key]?.toLowerCase().includes(str.toLowerCase())
        )
      )
      .map((item) => {
        const highlight = (text) =>
          text.replace(
            new RegExp(str, "gi"),
            (match) =>
              `<mark style="background: #fed136; color: white;">${match}</mark>`
          );

        return {
          ...item,
          title: highlight(item.title),
          description: highlight(item.description),
          palavrachave: highlight(item.palavrachave),
          textoverbete: highlight(item.textoverbete),
          autor: highlight(item.autor),
        };
      });

    dispatch({ type: "SEARCH_DATA", payload: newArr });
  };

  return (
    <div className="cards_BD">
      {/* Selector para alternar entre Verbetes e Documentos */}

      <div className="search-type-selector">
        <button
          className={`btn-type-selector ${searchType === "verbetes" ? "btn-active" : ""}`}
          onClick={() => setSearchType("verbetes")}
        >
          Buscar em Verbetes
        </button>
        <button
          className={`btn-type-selector ${searchType === "documentos" ? "btn-active" : ""}`}
          onClick={() => setSearchType("documentos")}
        >
          Buscar em Documentos
        </button>
      </div>


      {/* Renderiza o componente baseado na seleção */}
      {searchType === "verbetes" ? (
        <>
          <SearchBar onInput={(e) => handleInput(e)} />
          <div className="cards__container_BD">
            <div className="cards__wrapper_BD">
              <ul className="cards__items_BD">
              <div className="counter-container">

                <Counter
                  result={
                    state.searchData.length > 0 &&
                    state.searchData.length < state.data.length
                      ? state.searchData.length
                      : null
                  }
                />
                </div>
                {state.isLoading ? (
                  <div className="divspinner">
                    <Spinner animation="border" variant="warning" />
                  </div>
                ) : state.search.length > 0 ? (
                  state.searchData.map((props) => (
                    <CardItemBDBteste
                      key={props.id}
                      title={props.title}
                      description={props.textoverbete}
                      imgcard={props.imgcard}
                      linkid={props.id}
                      autor={props.autor}
                    />
                  ))
                ) : (
                  state.data.slice(0, visibleCount).map((props) => (
                    <CardItem
                      key={props.id}
                      title={props.title}
                      imgcard={props.imgcard}
                      linkid={props.id}
                    />
                  ))
                )}
              </ul>
              <button
                className="btn btn-verbete-main-verbete mt-10"
                style={{
                  display: "block",
                  margin: "0 auto",
                }}
                onClick={loadMore}
              >
                Carregar Mais Verbetes
              </button>
            </div>
          </div>
        </>
      ) : (
        <Documentos />
      )}
    </div>
  );
};

export default CardsVerbetes;
