import React, { useState, useEffect, useRef } from "react";
import fetchApi from "../../lib/fetch/index.ts";
import { useSecurityContext } from "../../context/SecurityContext.tsx";
import TextField from '@material-ui/core/TextField'


import "./CardDocumentos.css";
import CardItemDocumentos from './CardItemDocumentos.js';
import Footer from '../Footer';


const Documentos = () => {

  const context = useSecurityContext();
  const isMounted = useRef(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [alertControl, setAlertControl] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [forca, setForca] = useState(0);
  const [qtd, setQtd] = useState(5);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Estado para controlar a página atual
  const [maxPageCount, setMaxPageCount] = useState(5);

  const [datas, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
 
  const fetchData = async (page) => {
    try {
      const url = `https://api.umahistoriadapeninsula.com/api/pdf?page=${page}`;
      const res = await fetch(url, {
        headers: {
          Authorization: context.getToken(),
        },
      });
      const datas = await res.json();
      setData(datas);
      console.log("Dados da API:", datas);
    } catch (err) {
      console.log("Error:", err);
    }
  };
  
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);



  const search = async () => {
    try {
      setLoading(true);
      setList([]);
      setData({});

      const apiCall = await fetchApi.post(
        "/search",
        { searchText, forca, qtd, page },
        {
          headers: {
            Authorization: context.getToken(),
          },
        }
      );

      if (!apiCall.success) throw new Error(apiCall.message);

      if (isMounted.current) {
        setList(apiCall.data.list);
        setPage(apiCall.data.page);
        setShowPagination(false);
        if (apiCall.data.page < apiCall.data.totalPages) setShowMoreButton(true);
        else setShowMoreButton(false);
      }

      setLoading(false);
    } catch (error) {
      if (isMounted.current) {
        setAlertMessage(error.message);
        setAlertControl(true);
        setLoading(false);
      }
    }
  };


  const moreRersults = async () => {
    try {
      setLoading(true);

      const newPage = page + 1;

      const apiCall = await fetchApi.post(
        "/search",
        { searchText, forca, qtd, page: newPage },
        {
          headers: {
            Authorization: context.getToken(),
          },
        }
      );

      if (!apiCall.success) throw new Error(apiCall.message);

      setList((list) => list.concat(apiCall.data.list));
      setPage(apiCall.data.page);

      if (apiCall.data.page < apiCall.data.totalPages) setShowMoreButton(true);
      else setShowMoreButton(false);

      setLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertControl(true);
      setLoading(false);
    }
  };

  const showThisFragnment = async (params: { id: string; page: number }) => {
    try {
      setLoading(true);
      const apiCall = await fetchApi.get(
        `/search/showfragment?id=${params.id}&page=${params.page}`,
        {
          headers: {
            Authorization: context.getToken(),
          },
        }
      );

      if (!apiCall.success) throw new Error(apiCall.message);

      window.open(apiCall.data, "_blank");
      setLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertControl(true);
      setLoading(false);
    }
  };



  return (

    
    <div>
      {/* <div class="TituloBancodeDa">
        <div class="row">
          <div class="col-md-12">
            <h1>Documentos Obras pastorais e Doutrinárias</h1>
          </div>
        </div>
      </div> */}


      

      <section class="verbetesIndex-section" id="">
        <div class="container">
          <div className="row">
            <div className='col-lg-12'>

            <div className="post-content text-center">
  <div className="DivTextField">
  <TextField
      multiline
      minRows={4}
      label="Faça uma busca em nossos Documentos ..."
      placeholder="Buscar"
      style={{ width: "100%" }}
      color="warning"
      onChange={(e) => {
        const input = e.target.value;
        setSearchText(input);

        // Atualiza a página somente se o texto tem pelo menos 16 caracteres
        if (input.length >= 5) {
          setPage(1);
        }
      }}
      helperText={
        searchText.length > 0 && searchText.length < 5
          ? "A busca deve conter pelo menos 5 caracteres."
          : ""
      }
      error={searchText.length > 0 && searchText.length < 5}
    />
  </div>
 

                <div className="divDocumentosPdf">
                  {/* <label className="labelDocumentosPdf">Escolher Nível de Busca</label>
                  <div className="custom-input">
                    <input
                      type="range"
                      min={0}
                      max={5}
                      value={forca}
                      onChange={(e) => {
                        setForca(Number(e.target.value));
                        setPage(1);
                      }}
                    />
                    <span>{forca}</span>
                  </div> */}

                  {/* <div className="divDocumentosPdf">
                    <label className="labelDocumentosPdf">Quantidade de registros por página</label>
                    <div className="custom-input">
                      <input
                        type="range"
                        min={5}
                        max={50}
                        step={5}
                        value={qtd}
                        onChange={(e) => {
                          setQtd(Number(e.target.value));
                          setPage(1);
                        }}
                      />
                      <span>{qtd}</span>
                    </div>
                  </div> */}
                </div>

                <div className="pdf-tbn-documentos">
                  <button className='btn-documento-buscar' onClick={search}>Buscar</button>
                </div>

                <div>
                  <div className='cards__container_Docs'>
                    <div className='cards__wrapper_Docs'>
                      <ul className='cards__items_Docs'>
                        {list.length > 0 ? (
                          list.map((listItem, index) => (
                            <div key={`${new Date().getMilliseconds()}-${index}`}>
                              <CardItemDocumentos
                                title={listItem.title}
                                author={listItem.author}
                                page={listItem.fragmentBody.page}
                                id={listItem.id}
                                pdfPath={`https://bucket.server.umahistoriadapeninsula.com/pdf/fragment/${listItem.title}-fragment-${listItem.fragmentBody.page}.pdf`}
                                showLink={true}
                                imgcard={listItem.capa}
                              />
                            </div>
                          ))
                        ) : (
                          <></>
                        )}

{datas.success && datas.data.list.length > 0 ? (
  datas.data.list.map((listItem, index) => (
    <div key={listItem.id}>
      <div key={`${new Date().getMilliseconds()}-${index}`}>
        <CardItemDocumentos
          title={listItem.title}
          author={listItem.author}
          page={currentPage}
          id={listItem.id}
          imgcard={listItem.capa}
          showLink={true}
        />
      </div>
    </div>
  ))
) : (
  <p>  </p>
)}
 

                      </ul>
                      {showPagination && (
  <div>
    {Array.from({ length: maxPageCount }).map((_, index) => {
      const pageNumber = index + 1;
      return (
        <button key={pageNumber} onClick={() => setCurrentPage(pageNumber)}>
          {pageNumber}
        </button>
      );
    })}
  </div>
)}
                      {showMoreButton && (
                        <div className="div-button">
                          <button
                            fullWidth
                            variant="contained"
                            onClick={moreRersults}
                          >
                            Mostrar mais resultados
                          </button>
                        </div>
                      )}



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 
    </div>
  );
};

export default Documentos;