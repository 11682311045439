import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "animate.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    animation: "fadeIn 2s ease-in-out",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: "#FDC92B",
  },
  audioContainer: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}));

function SegundoEpisodio() {
  const classes = useStyles();

  return (
    <>  
       <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>

      <Container className={classes.root}>
        <Card>
          <CardContent>
            <Typography variant="h5" className={classes.sectionTitle}>
              Segundo Episódio: O Gato e o Rato
            </Typography>
            <Typography variant="body1" paragraph>
            Neste segundo episódio, intitulado “O Gato e o Rato”, adentramos, diretamente, nos ensinamentos morais do livro “Calila e Dimna”, destacando a importância das escolhas em nossas amizades. Na fábula narrada, os ouvintes são apresentados à relevância da amizade na resolução de conflitos, além de temas como justiça, traição e ambição. O objetivo do episódio é, portanto, demonstrar, de forma didática, como a sabedoria é abordada nas fábulas, ensinando os ouvintes a reconhecer e valorizar boas ações. Dessa forma, verifica-se que o caráter moral presente em “Calila e Dimna” se relaciona à astúcia e à habilidade de perceber-se na virtude, refletindo outra característica essencial do livro: o pragmatismo na formação de líderes.
            
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Apresentadores
                </Typography>
                <Typography variant="body1">
                Bruna Dias Souza Freire (História UNESP – Assis)
                </Typography>
                <Typography variant="body1">
                Roberto Caliani Janeiro Filho (História UNESP – Assis)
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Produção, Roteiro e Edição
                </Typography>
                <Typography variant="body1">
                Bruna Dias Souza Freire (História UNESP – Assis)
                </Typography>
                <Typography variant="body1">
                Roberto Caliani Janeiro Filho (História UNESP – Assis)

                </Typography>
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.sectionTitle}>
              Fonte
            </Typography>
            <Typography variant="body1" paragraph>
            CALILA E Dimna. Ed. Juan Manuel Cacho Blecua e María Jesús Lacarra. Madrid: Castalia, 1985. Adaptação do conto originalmente intitulado: <em>Del Gato et del Mor.</em>
            </Typography>

            <Typography variant="h6" className={classes.sectionTitle}>
              Créditos
            </Typography>
            <ul>
              <li>
                Rosa das Rosas (Cantigas de Santa Maria: 10) – <a href="https://encr.pw/4gID5" target="_blank" rel="noopener noreferrer">Link</a>
              </li>
              <li>
                The Ship Far Away (medieval folk) – <a href="https://pixabay.com/es/music/gente-the-ship-far-away-medieval-folk-255174/" target="_blank" rel="noopener noreferrer">Pixabay</a>
              </li>
              <li>
                Medieval Music - Castle of Dreams – <a href="https://pixabay.com/music/solo-classical-instruments-medieval-music-castle-of-dreams-212203/" target="_blank" rel="noopener noreferrer">Pixabay</a>
              </li>
            </ul>
         

          <CardActions className={classes.audioContainer}>
            <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#333', marginBottom: '1rem' }}>
              Ouça o Episódio:
            </Typography>
            <audio controls style={{ width: '100%', border: '2px solid #4CAF50', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <source src="https://bucket.server.umahistoriadapeninsula.com/podcast/Episodio2_Gato_e_Rato.mp3" type="audio/mpeg" />
              Seu navegador não suporta o player de áudio.
            </audio>
          </CardActions>

          <Typography variant="h6" className={classes.sectionTitle}>
              Créditos Detalhados
            </Typography>
            <Typography variant="body1" paragraph>
              00:00 até 01:45; 13:00 até 14:30: Rosa das Rosas (Cantigas de Santa Maria: 10) from Malandança: Unha noite na corte do Rei Afonso” ed. Clave Records. Disponível em: {" "}
              <a href="https://encr.pw/4gID5" target="_blank" rel="noopener noreferrer">
                encr.pw/4gID5
              </a>
            </Typography>
            <Typography variant="body1" paragraph>
              01:50 até 03:15: The Ship Far Away (medieval folk) by alanajordan. Disponível em: {" "}
              <a href="https://pixabay.com/es/music/gente-the-ship-far-away-medieval-folk-255174/" target="_blank" rel="noopener noreferrer">
                pixabay.com
              </a>
            </Typography>
            <Typography variant="body1" paragraph>
              04:15 até 13:00: Medieval Music - Castle of Dreams by Tech Oasis. Disponível em: {" "}
              <a href="https://pixabay.com/music/solo-classical-instruments-medieval-music-castle-of-dreams-212203/" target="_blank" rel="noopener noreferrer">
                pixabay.com
              </a>
            </Typography>
            </CardContent>
        </Card>
      </Container>
    </>
  );
}

export default SegundoEpisodio;
