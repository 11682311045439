import React from 'react';
import './DivulgacaoCientifica.css'
 import Footer from '../components/Footer';
 import DivulgacaoCientificaComponent from '../components/DivulgacaoCientifica/DivulgacaoCientificaComponent'
 import { Typography, Container } from '@material-ui/core';
 import { makeStyles } from '@material-ui/core/styles';
  import Podcast from '../components/DivulgacaoCientifica/Podcast/PodcastList'

 
// Estilos personalizados
const useStyles = makeStyles((theme) => ({
  titleMaterias: {
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: "#FDC92B",  
  },
  line: {
    width: '100%', // Largura total
    height: '2px', // Espessura da linha
    backgroundColor: '#FF5733', // Cor da linha
    margin: `${theme.spacing(1)}px 0`, // Margens acima e abaixo da linha
  },
}));

export default function DivulgacaoCientifica() {
  const classes = useStyles(); // Chama os estilos personalizados

    return (
      
            <div>
              <div className="DivulgacaoCientifica">
                <div className="row">
                  <div className="col-md-12">
                    <Typography variant="h3" component="h1" gutterBottom>
                      Divulgação Científica
                    </Typography>
                  </div>
                </div>
              </div>
        
              {/* Título para separar as seções */}
              <Container>
              <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={classes.titleMaterias} // Aplica a classe personalizada
        >
          Podcast
        </Typography>
        <div className={classes.line}></div> {/* Linha decorativa */}

              </Container>
        
              {/* Seção de Podcast */}
              <Podcast />
        
              {/* Título para as matérias */}
              <Container>
              <Typography
          variant="h4"
          component="h2"
          gutterBottom
          className={classes.titleMaterias} // Aplica a classe personalizada
        >
          Matérias
        </Typography>
        <div className={classes.line}></div> {/* Linha decorativa */}

              </Container>
        
              {/* Seção de matérias */}
              <DivulgacaoCientificaComponent />
        
              {/* Rodapé */}
              <Footer />
            </div>
    )
    }