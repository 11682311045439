import React from 'react';
import { Link } from 'react-router-dom';
import './podcast.css'

function CardsItemPodcast(props) {
    return (
        <li className='cards__item_podcast'>
            <Link className='cards__item__link_podcast' to={props.path}>
                <figure className='cards__item__pic-wrap_podcast' data-category={props.label}>
                    <img
                        className='cards__item__img_podcast'
                        alt='Podcast'
                        src={props.src}
                    />
                </figure>
                <div className='cards__item__info_podcast'>
                    <h5 className='cards__item__name_podcast'>{props.name}</h5>
                    <p className='cards__item__text_podcast'>{props.text}</p>
                </div>
            </Link>
        </li>
    );
} 

export default CardsItemPodcast;
