import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "animate.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    animation: "fadeIn 2s ease-in-out",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    textTransform: "uppercase",
  }, 
  sectionTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: "#FDC92B",  
  },
  audioContainer: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: "uppercase",
  },
}));

function PrimeiroEpisodio() {
  const classes = useStyles();

  return (
    <>  
       <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>

 <Container className={classes.root}>
      <Card>
        <CardContent>
        <Typography 
  variant="h5" 
  className={classes.sectionTitle} 
  style={{ color: "#FDC92B" }}
 
>            Primeiro Episódio: A Jornada de Berzebuey e a Busca pela Sabedoria
          </Typography>
          <Typography variant="body1" paragraph>
          Neste episódio de abertura, “A Jornada de Berzebuey e a Busca pela Sabedoria”, é narrada a primeira história presente no livro de “Calila e Dimna”, ou seja, as aventuras de Berzebuey nas terras da Índia. Além de mantermos os nomes originais das personagens, nossa intenção, ao longo da narrativa, foi não apenas entreter e divertir os ouvintes, mas também destacar o constante diálogo entre o Rei e o Filósofo, ressaltando a essência sapiencial da obra.
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Apresentadores
              </Typography>
              <Typography variant="body1">
              Bruna Dias Souza Freire (História UNESP – Assis)
              </Typography>
              <Typography variant="body1">
              Roberto Caliani Janeiro Filho (História UNESP – Assis)
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Produção, Roteiro e Edição
              </Typography>
              <Typography variant="body1">
              Bruna Dias Souza Freire (História UNESP – Assis)
              </Typography>
              <Typography variant="body1">
                Roberto Caliani Janeiro Filho (História UNESP – Assis)
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h6" className={classes.sectionTitle}>
            Fonte
          </Typography>
          <Typography variant="body1" paragraph>
          Calila e Dimna. Ed. Juan Manuel Cacho Blecua e María Jesús Lacarra. Madrid: Castalia, 1985. Adaptação do conto originalmente intitulado:{" "}
            <em>
            Commo el Rey Sirechuel enbió a Berzebuey a Tierra de India
            </em>
            .
          </Typography>

          <Typography variant="h6" className={classes.sectionTitle}>
            Créditos
          </Typography>
          <ul>
            <li>
              Santa Maria, Strela do Dia (Medieval Cantiga) –{" "}
              <a
                href="https://www.youtube.com/watch?v=hCGyDmclpS8"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
            </li>
            <li>
              One Bard Band by Alexander Nakarada –{" "}
              <a
                href="https://creatorchords.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                CreatorChords
              </a>
            </li>
          </ul>
        </CardContent>
       
        <CardActions className={classes.audioContainer}>
          <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#333', marginBottom: '1rem' }}>
            Ouça o Episódio:
          </Typography>
          <audio 
            controls 
            style={{
              width: '100%',
              border: '2px solid #4CAF50',  // Cor de borda
              borderRadius: '8px', // Bordas arredondadas
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Sombra para dar destaque
              padding: '10px',
            }}
          >
            <source 
              src="https://bucket.server.umahistoriadapeninsula.com/podcast/A_Jornada_de_Berzebuey_e_a_Busca_pela_Sabedoria.mp3" 
              type="audio/mpeg" 
            />
            Seu navegador não suporta o player de áudio.
          </audio>
        </CardActions>

        <CardContent>
          <Typography variant="h6" className={classes.sectionTitle}>
            Créditos Detalhados
          </Typography>
          <Typography variant="body1" paragraph>
            00:00 até 02:00; 13:30 até 15:48: Santa Maria, Strela do Dia (Medieval Cantiga) from "O Jerusalem" – APOLLO'S FIRE, Powell, Sorrell. Artistic Director Live performance, November 11, 2018. Disponível em:{" "}
            <a href="https://www.youtube.com/watch?v=hCGyDmclpS8" target="_blank" rel="noopener noreferrer">
              YouTube
            </a>
          </Typography>
          <Typography variant="body1" paragraph>
            02:00 até 04:17: One Bard Band by Alexander Nakarada (CreatorChords) |{" "}
            <a href="https://creatorchords.com" target="_blank" rel="noopener noreferrer">
              CreatorChords
            </a>{" "}
            Music promoted by{" "}
            <a href="https://www.free-stock-music.com" target="_blank" rel="noopener noreferrer">
              Free Stock Music
            </a>
          </Typography>
          <Typography variant="body1" paragraph>
            06:40 até 07:15; 07:40 até 08:00: Festa Celta, feliz medieval celta por Hanso Tomodachi. Disponível em:{" "}
            <a href="https://pixabay.com/ru/music/celtic-party-happy-celtic-medieval-243758/" target="_blank" rel="noopener noreferrer">
              Pixabay
            </a>
          </Typography>
          <Typography variant="body1" paragraph>
            08:10 até 09:23: Beyond New Horizons - Free Epic Viking Medieval Soundtrack by Gioele Fazzeri. Disponível em:{" "}
            <a href="https://pixabay.com/pt/music/titulo-principal-beyond-new-horizons-free-epic-viking-medieval-soundtrack-22081/" target="_blank" rel="noopener noreferrer">
              Pixabay
            </a>
          </Typography>
          <Typography variant="body1" paragraph>
            09:20 até 11:00: Indian music with sitar, tanpura and sarangi by ShidenBeatsMusic. Disponível em:{" "}
            <a href="https://pixabay.com/pt/music/india-indian-music-with-sitar-tanpura-and-sarangi-74577/" target="_blank" rel="noopener noreferrer">
              Pixabay
            </a>
          </Typography>
        </CardContent>
      </Card>
    </Container>
    </>
  );
}

export default PrimeiroEpisodio;
