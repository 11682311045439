import React, { useState, useEffect } from "react"
import { Navbar, Nav, Container, Collapse } from "react-bootstrap"
import logo from '../assets/img/logo_florA.png'
import navIcon1 from '../assets/img/youtube.svg'
import navIcon2 from '../assets/img/twitter.svg'
import navIcon3 from '../assets/img/instagram.svg'
import navIcon4 from '../assets/img/face.svg'

import './Css/NavBar.css'
 
      
 function NavBarH() {
  
  const [activeLink, setClick] = useState(false);
   const [scrolled, setScrolled] = useState(false);

   const closeMobileMenu = () => setClick(false);

   
   

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
        
      } else {
        setScrolled(false);
      }
      
    }
  
 
  window.addEventListener("scroll", onScroll);
 
  return () => window.removeEventListener("scroll", onScroll);
}, [])

 

  return (
     <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
     
      
     <Container className="containerCss">  
      
      <Navbar.Brand href="/">
         <Nav.Link href="/" className={activeLink === 'home' ? 'nav-links' : 'navbar-link'} onClick={() => closeMobileMenu('Home')}>Uma História da Península</Nav.Link> 
         
             
        </Navbar.Brand>
 
         <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"> </span>
        </Navbar.Toggle>  
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
 
 
          <Nav.Link href="/DivulgacaoCientifica" className={activeLink === 'DivulgacaoCientifica' ? 'navbar-link' : 'navbar-link'} onClick={() => closeMobileMenu('DivulgacaoCientifica')}>Divulgação científica</Nav.Link>
          <Nav.Link href="/Convenios" className={activeLink === 'Convenios' ? 'navbar-link' : 'navbar-link'} onClick={() => closeMobileMenu('Convenios')}>CONVÊNIOS</Nav.Link>
            <Nav.Link href="/BancodeDados" className={activeLink === 'BancodeDados' ? 'navbar-link' : 'navbar-link'} onClick={() => closeMobileMenu('BancodeDados')}>BANCO DE DADOS</Nav.Link>
            {/* <Nav.Link href="/Documentos" className={activeLink === 'Documentos' ? 'nav-links' : 'navbar-link'} onClick={() => closeMobileMenu('Documentos')}>Documentos</Nav.Link> */}

            <Nav.Link href="/Eventos" className={activeLink === 'Eventos' ? 'navbar-link' : 'navbar-link'} onClick={() => closeMobileMenu('Eventos')}>EVENTOS</Nav.Link>
            <Nav.Link href="/FaleConosco" className={activeLink === 'FaleConosco' ? 'navbar-link' : 'navbar-link'} onClick={() => closeMobileMenu('FaleConosco')}>Fale Conosco</Nav.Link>
             <Nav.Link href="/Sobre" className={activeLink === 'SobreNos' ? 'nav-links' : 'navbar-link'} onClick={() => closeMobileMenu('SobreNos')}>SOBRE NÓS</Nav.Link>

           
           
            {/* <div className="social-icon">
              <a href="https://www.youtube.com/channel/UCOqszOrTMrhmlUvWCKXiT0A"><img src={navIcon1} alt="" /></a>
              <a href="https://twitter.com/DaPeninsula"><img src={navIcon2} alt="" /></a>
              <a href="https://www.instagram.com/historiadapeninsula/"><img src={navIcon3} alt="" /></a>
              <a href="https://www.facebook.com/profile.php?id=61558268759636&locale=pt_BR"><img src={navIcon4} alt="" /></a>
            
            </div> */}
            
          </Nav>
                 
        </Navbar.Collapse>
        </Container> 
         
    </Navbar>

   )
}
 
export default NavBarH;
